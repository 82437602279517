import { t } from "i18next";
import { h } from "hyperapp";
import { Card, Enter, Form, FormInput, Button } from "@app/elements";
import * as constants from "@app/constants";

import ResponsiveModalLayout from "@app/layouts/modal/layout";

import icoRlsHeal from "@app/img/deco/illu-rsl-heal.png";
import icoRlsHealx2 from "@app/img/deco/illu-rsl-heal@2x.png";

export const ModalConsentHeal = (props, actions) => (
	<ResponsiveModalLayout page={"appbanner"} closeModal={props.closeModal}>
		<div
			class="appbanner-central"
			style={{ "background-color": "#f8fafb" }}>
			<Card
				classes={"btzPageCard btzBackgroundCover"}
				kind={"datas"}
				style={{ "padding-bottom": "30px" }}>
				<Card.Header classes={"btzCard-btzHeader-btzPage"}>
					<Enter
						time={constants.custo.ANIM_DURATION}
						easing={constants.custo.ANIM_EASING}
						css={constants.custo.ANIM_CSS}>
						<p style="max-width: 310px;margin: 10px auto 40px;">
							{t("Souhaitez-vous participer à nos recherches ?")}
						</p>
						<img
							style="display: block;"
							src={icoRlsHeal}
							srcset={`${icoRlsHealx2} 2x`}
							alt=""
						/>
						<p style="display: block;max-width: 510px;margin: auto auto 36px;">
							{t(
								"Resilience souhaite collecter vos données non-nominatives, y compris celles collectées dans le cadre de votre prise en charge médicale, afin de conduire des recherches en oncologie. Votre choix n’est pas lié à votre prise en charge par votre établissement."
							)}
							<a
								// href={`https://${
								// 	localStorage.getItem("language") === "nl"
								// 		? "nl."
								// 		: localStorage.removeItem(
								// 				"language"
								// 		  ) === "en"
								// 		? "en."
								// 		: ""
								// }resilience.care/portail-de-transparence`}
								href={t(
									"http://resilience.care/portail-de-transparence"
								)}
								target="_blank"
								style="margin-left: 5px;font-size: 16px;">
								{t(
									"En savoir plus dans notre Portail de transparence"
								)}
							</a>
						</p>
						<Form
							onsubmit={(event) => event.preventDefault()}
							autocomplete={"on"}
							style={{
								margin: "auto auto 25px auto",
								"max-width": "420px",
							}}>
							<button
								type="submit"
								disabled
								style="display: none"
								aria-hidden="true"></button>

							<Form.Group
								classes="btzForm-btzFooter"
								style={{ "max-width": "420px" }}>
								<Button
									flat
									cancel
									onclick={() => {
										props.onAcceptHealConsent(true);
									}}>
									{t("J'accepte")}
								</Button>
								<Button
									flat
									cancel
									onclick={() => {
										props.onAcceptHealConsent(false);
									}}>
									{t("Je refuse")}
								</Button>
							</Form.Group>
						</Form>
					</Enter>
				</Card.Header>
			</Card>
		</div>
	</ResponsiveModalLayout>
);
